import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';
import { moneyFormatter } from '../utils/formatterUtil';
import useRevolvingLineOfferController from '../hooks/controllers/useRevolvingLineOfferController';
import Loading from './commons/Loading';

const RevolvingLineOffer = ({ loanRequest }) => {
  const {
    globalLoading,
    activationLoading,
    doActivation,
  } = useRevolvingLineOfferController(loanRequest);

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography
          align="left"
          sx={{ fontSize: { xs: '24px', md: '36px' }, fontWeight: 700, fontFamily: 'Poppins' }}
        >
          {parse(fillMessageWith(i18n.RevolvingLineOffer.title, moneyFormatter(loanRequest.response.maxAmount)))}
        </Typography>
        <Typography
          align="left"
          sx={{ fontSize: { xs: '16px', md: '20px' }, marginTop: { xs: '20px', md: '12px' }, marginBottom: { xs: '16px', md: '32px' } }}
        >
          {parse(i18n.RevolvingLineOffer.description)}
        </Typography>
        {i18n.RevolvingLineOffer.bullets.map((bullet) => (
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }} key={bullet}>
            <img src="/assets/images/bullet.svg" alt="bullet" style={{ marginRight: '8px' }} />
            <Typography align="left" sx={{ fontSize: { xs: '14px', md: '16px' } }}>
              {parse(bullet)}
            </Typography>
          </Box>
        ))}
        <Button
          className="btn-primary"
          fullWidth
          sx={{ marginTop: { xs: '39px', md: '48px' } }}
          onClick={doActivation}
          loading={activationLoading}
        >
          {i18n.RevolvingLineOffer.cta}
        </Button>
      </Grid>
    </Grid>
  );
};

RevolvingLineOffer.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default RevolvingLineOffer;

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useToaster } from '../../contexts/ToasterContext';
import { getResponseErrorOrDefault } from '../../utils/functionsUtil';
import { loanRedirect } from '../../components/LoanRequestRedirectService';
import { getOnboardingCustomOffer, setInstallment } from '../../api/onboardingService';

const useRevolvingLineOfferController = (loanRequest) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const { showToast } = useToaster();
  const [globalLoading, setGlobalLoading] = useState(true);
  const [activationLoading, setActivationLoading] = useState(false);
  const [installments, setInstallments] = useState(false);

  const doActivation = async () => {
    try {
      setActivationLoading(true);
      const installment = {
        ...installments,
        requestedAmount: loanRequest.response.maxAmount,
        isDual: false,
      };
      await setInstallment(loanRequest.hashKey, installment);
      loanRedirect(loanRequest, navigate, queryParams.toString());
    } catch (error) {
      showToast(getResponseErrorOrDefault(error), 'error');
    } finally {
      setActivationLoading(false);
    }
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      const { data: installmentList } = await getOnboardingCustomOffer(loanRequest.hashKey, loanRequest.response.maxAmount, true);
      setInstallments(installmentList[0]);
    } catch (error) {
      showToast(getResponseErrorOrDefault(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    activationLoading,
    doActivation,
  };
};

export default useRevolvingLineOfferController;

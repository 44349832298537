import parse from 'html-react-parser';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../common/i18n';
import useNavigation from '../hooks/useNavigation';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '24px',
    fontWeight: '700',
    fontFamily: 'Poppins',
    marginTop: '24px',
  },
  description: {
    fontSize: '16px',
    marginTop: '8px',
  },
}));

const OnboardingFinalRevolvingLine = () => {
  const classes = useStyles();
  const { goToLendingSelfieHome, goToRevolvingLine } = useNavigation();

  return (
    <>
      <Grid container mb={4}>
        <Grid item xs={12}>
          <img src="/assets/images/success.svg" alt="success" />
          <Typography className={classes.title}>{i18n.OnboardingFinalRevolvingLine.title}</Typography>
          <Typography className={classes.description}>{parse(i18n.OnboardingFinalRevolvingLine.description)}</Typography>
        </Grid>
      </Grid>
      <Button
        variant="primary"
        className="btn-primary"
        type="button"
        fullWidth
        style={{ marginTop: '24px' }}
        onClick={goToLendingSelfieHome}
      >
        {i18n.OnboardingFinalRevolvingLine.goHome }
      </Button>
      <Button
        variant="secondary"
        className="btn-primary"
        type="button"
        fullWidth
        style={{ marginTop: '8px' }}
        onClick={goToRevolvingLine}
      >
        {i18n.OnboardingFinalRevolvingLine.goRevolvingLine }
      </Button>
    </>
  );
};

export default OnboardingFinalRevolvingLine;

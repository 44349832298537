import i18n from '../common/i18n';
import rechargeClaroImg from '../assets/imgs/recharge_9.png';
import rechargePersonalImg from '../assets/imgs/recharge_16.png';
import rechargeNextelImg from '../assets/imgs/recharge_577.png';
import rechargeMovistarImg from '../assets/imgs/recharge_1488.png';
import rechargeTuentiImg from '../assets/imgs/recharge_3144.png';
import RQDNIfrente from '../assets/imgs/RQDNIfrente.png';
import RQDNIdorso from '../assets/imgs/RQDNIdorso.png';
import RQFoto from '../assets/imgs/RQFoto.png';
import RQTarjetaDebitoDorso from '../assets/imgs/RQTarjetaDebitoDorso.png';
import RQTarjetaDebitoFrente from '../assets/imgs/RQTarjetaDebitoFrente.png';
import { DOCUMENT_LOAD_ALLOW_CONTINUE_ON_DESKTOP, getEnv } from '../api/env';
import { referenceConstant } from './referenceConstant';

const fillRegexp = /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g;

export const fillMessageWith = (message, ...args) => {
  const values = args.reduce((acc, v, i) => {
    acc[i.toString()] = v;
    return acc;
  }, {});
  return message.replace(fillRegexp, (exp, prop) => (values[prop] !== undefined ? values[prop] : ''));
};

export const isMobileOperatingSystem = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.window.opera;
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export const getResponseErrorOrDefault = (error) => {
  if (error.response && error.response.data && error.response.data.errorCode
      && referenceConstant[error.response.data.errorCode]) {
    return referenceConstant[error.response.data.errorCode];
  }
  if (error.response && error.response.data && error.response.data.cause) {
    return error.response.data.cause;
  }
  return i18n.genericErrorMessage;
};

export const resolveRechargeCompanyLogo = (companyCode) => {
  switch (companyCode) {
    case '9': return rechargeClaroImg;
    case '16': return rechargePersonalImg;
    case '577': return rechargeNextelImg;
    case '1488': return rechargeMovistarImg;
    case '3144': return rechargeTuentiImg;
    default: return '';
  }
};

export const resolveCreditCardType = (creditCardNumber) => {
  let result = 'unknown';
  if (/^5[1-5]/.test(creditCardNumber)) {
    result = 'mastercard';
  } else if (/^4/.test(creditCardNumber)) {
    result = 'visa';
  } else if (/^(5010|5012|5020|5038|5887|5895|5896|5894|5892|6010|6304|6759|6761|6763)/.test(creditCardNumber)) {
    result = 'maestro3';
  } else if (/^3[47]/.test(creditCardNumber)) {
    result = 'amex';
  } else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
    result = 'diners';
  } else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
    result = 'discover';
  }
  return result;
};

export const EcommerceEvents = Object.freeze({
  ADD_TO_CART: 'add_to_cart',
  PURCHASE: 'purchase',
  BEGIN_CHECKOUT: 'begin_checkout',
});

export const sendDataLayer = (event) => {
  window.dataLayer.push(event);
};

export const sendDataLayerEvent = (event, amount, loanId) => {
  // este push con "ecommerce: null" da un warning, pero se recomienda hacerlo segundo la doc
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event,
    currency: 'ARS',
    value: amount,
    ecommerce: {
      items: [{
        item_name: 'Tradicional Web',
        item_id: loanId,
        affiliation: '',
        price: amount,
      }],
    },
  });
};

export const sendDataLayerEventObject = (event) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(event);
};

export const sendDataLayerCustomerInfo = (loan) => {
  window.dataLayer.push({
    CMPTesC: !loan.isNewClient,
    organismo: loan.subProductId,
  });
};

export const isAllowedContinueOnDesktop = () => {
  const value = getEnv(DOCUMENT_LOAD_ALLOW_CONTINUE_ON_DESKTOP);
  return value.toLocaleUpperCase() === 'TRUE';
};

export const getUploadDocumentImg = (documentName) => {
  switch (documentName) {
    case 'RQDNIfrente': return RQDNIfrente;
    case 'RQDNIdorso': return RQDNIdorso;
    case 'RQFoto': return RQFoto;
    case 'RQTarjetaDebitoDorso': return RQTarjetaDebitoDorso;
    case 'RQTarjetaDebitoFrente': return RQTarjetaDebitoFrente;
    default: return '';
  }
};

export const getMonth = (date) => {
  let resolvedMonth;
  const utcDate = date ? new Date(new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' })) : undefined;
  if (utcDate) {
    const dateMonth = utcDate.toLocaleString('es-ES', { month: 'long' });
    resolvedMonth = dateMonth.charAt(0).toUpperCase() + dateMonth.slice(1);
  }
  return resolvedMonth;
};

export const isRechargeLoan = (loanRequest) => loanRequest?.productToPay?.rechargeType;

export const isBillLoan = (loanRequest) => loanRequest?.productToPay?.billType;

export const isEcommerceCartLoan = (loanRequest) => loanRequest?.productToPay?.ecommerceCartType;

export const isBranchCashLoan = (loanRequest) => loanRequest?.commerceId === 99;

export const isPrepaidCardLoan = (loanRequest) => loanRequest?.disbursementMode === 'PREPAID_CARD';

export const isRevolvingLine = (loanRequest) => loanRequest.productId === 46;

export const getServiceAmount = (loanRequest) => {
  let { amount } = loanRequest.productToPay?.products[0].productAttributes;
  if (!amount) {
    amount = loanRequest.productToPay?.products[0].productAttributes.IV1;
  }
  return amount;
};

export const getServiceCompanyName = (loanRequest) =>
  loanRequest.productToPay.products[0].productAttributes.companyName.toLowerCase();

export const dataURLtoBlob = (dataUrl) => {
  const byteString = atob(dataUrl.split(',')[1]);
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const getCardType = (cardNumber) => {
  let result = '';
  if (/^5[1-5]/.test(cardNumber)) {
    // first check for MasterCard
    result = 'mastercard';
  } else if (/^4/.test(cardNumber)) {
    // then check for Visa
    result = 'visa';
  } else if (/^(5010|5012|5020|5038|5887|5895|5896|5894|5892|6010|6304|6759|6761|6763)/.test(cardNumber)) {
    // then check for Maestro
    result = 'maestro';
  } else if (/^3[47]/.test(cardNumber)) {
    // then check for AmEx
    result = 'amex';
  } else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(cardNumber)) {
    // then check for Diners
    result = 'diners';
  } else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(cardNumber)) {
    // then check for Discover
    result = 'discover';
  }
  return result;
};

export const maskPhoneNumber = (number) => {
  const start = number.slice(0, 2);
  const end = number.slice(-4);
  return `${start}****${end}`;
};

export const getErrorCodeMessage = (error) => {
  const errorCode = error?.response?.data?.errorCode || 'GENERIC_ERROR';
  return i18n.ErrorCode[errorCode];
};

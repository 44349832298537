import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  checkBlacklist, checkExistingLoanRequest, resolveCustomers, savePreSimulationLoan, sendVerificationCode,
  startWorkflow, updateCustomerData, updateSpecificCustomerData, updateVerifiedCodeAndEmail, verifyCode, verifyEmail, verifyEmailDomain,
} from '../../api/onboardingService';
import { fillMessageWith, getResponseErrorOrDefault, sendDataLayerEventObject } from '../../utils/functionsUtil';
import { ERROR_CODES } from '../../utils/errorCodeConstant';
import i18n from '../../common/i18n';
import { redirectToContinueURLSlashM, saveUrlSourceParameters } from '../../utils/commonServices';
import { routes } from '../../constants/routes';
import { loanRedirect } from '../../components/LoanRequestRedirectService';

const usePreOnboardingServicesAdwordsController = () => {
  const steps = {
    init: 'init',
    verificationCode: 'verificationCode',
    dni: 'dni',
    resolveCustomer: 'resolveCustomer',
  };

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const [calculatingOffer, setCalculatingOffer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerCellphone, setCustomerCellphone] = useState();
  const [resendMessage, setResendMessage] = useState();
  const [customerList, setCustomerList] = useState();
  const [prevHash, setPrevHash] = useState();
  const [errorMessage, setErrorMessage] = useState();

  console.log(setCalculatingOffer, setCustomerEmail, setCustomerCellphone,
    resendMessage, setResendMessage, setCustomerList, setPrevHash, setErrorMessage, customerList, prevHash, errorMessage);

  const sendCode = async (cellphone, email, resendCode) => {
    try {
      sendDataLayerEventObject({ event: 'enviar_codigo', tipoValidacion: 'SMS' });
      await sendVerificationCode(cellphone, cellphone, email, resendCode);
      if (!resendCode) {
        await savePreSimulationLoan(cellphone, email, undefined, 'service-adwords');
      }
      setCustomerEmail(email);
      setCustomerCellphone(cellphone);
      setCurrentStep(steps.verificationCode);
      window.scrollTo(0, 0);
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_SENDING_SMS_BUT_EMAIL_SENT) {
        setResendMessage(`${i18n.VerificationCodeStep.resendMessageJustMail} ${customerEmail}`);
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const doChangeCellphone = () => {
    setCustomerCellphone(undefined);
    setCurrentStep(steps.init);
    window.scrollTo(0, 0);
  };

  const doChangeEmail = () => {
    setCustomerEmail(undefined);
    setCurrentStep(steps.init);
    window.scrollTo(0, 0);
  };

  const resendVerificationCode = () => {
    sendCode(customerCellphone, customerEmail, true);
    setResendMessage(
      fillMessageWith(i18n.VerificationCodeStep.resendMessage, i18n.VerificationCodeStep.methodSmsLabel),
    );
  };

  const doSubmitVerificationCode = async ({ code }) => {
    try {
      setLoading(true);
      const data = {
        userInputName: 'WT_PHONE',
        userInputVerificationCode: code,
        userInputValue: customerCellphone,
      };
      const res = await verifyCode(data);
      if (res.data.result) {
        setCurrentStep(steps.dni);
        window.scrollTo(0, 0);
      } else {
        setErrorMessage(i18n.VerificationCodeStep.errors.invalidCode);
      }
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const doSubmitContactInfo = async (data) => {
    try {
      await verifyEmailDomain(data.email);
      sendDataLayerEventObject({ event: 'validar_email' });
      setLoading(true);
      await updateCustomerData(undefined, data.email, 91);
      await sendCode(data.cellphone, data.email, false);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const doSubmitDNI = async (data) => {
    try {
      setLoading(true);
      const response = await resolveCustomers(data.dni);
      setCustomerList(response.data);
      setCurrentStep(steps.resolveCustomer);
      window.scrollTo(0, 0);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const startWorkflowProcess = async () => {
    try {
      setCalculatingOffer(true);
      window.scrollTo(0, 0);
      const res = await startWorkflow();
      await verifyEmail(res.data.hashKey);
      saveUrlSourceParameters(res.data.hashKey);
      loanRedirect(res.data, navigate, queryParams.toString());
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE) {
        navigate({
          pathname: `${routes.OnboardingErrorCode}/${ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE}`,
          search: `?${queryParams.toString()}`,
        });
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const initWorkflow = async (customer) => {
    try {
      const res = await checkExistingLoanRequest(customer.cuit);
      setPrevHash(res.data.hashKey);
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.SAPP_NO_PEN_CREDITS) {
        startWorkflowProcess();
      } else {
        setErrorMessage(i18n.unexpectedErrorMessage);
      }
    }
  };

  const doSubmitIdentityResolverStep = async (customer) => {
    try {
      // el loading ya deberia ser el de la calculadora tal vez
      setLoading(true);
      await updateSpecificCustomerData(customer);

      await checkBlacklist(customerCellphone);

      initWorkflow(customer);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const doChangeCustomer = () => {
    setCurrentStep(steps.dni);
    window.scrollTo(0, 0);
  };

  const reStartFlow = () => {
    setPrevHash(undefined);
    setCustomerEmail(undefined);
    setCustomerCellphone(undefined);
    setCurrentStep(steps.init);
    window.scrollTo(0, 0);
  };

  const doContinueLoanRequest = async () => {
    try {
      await updateVerifiedCodeAndEmail(customerCellphone, customerEmail, prevHash);
      redirectToContinueURLSlashM(prevHash);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  return {
    steps,
    calculatingOffer,
    loading,
    customerCellphone,
    customerEmail,
    currentStep,
    setCurrentStep,
    doSubmitContactInfo,
    doChangeCellphone,
    resendVerificationCode,
    doSubmitVerificationCode,
    resendMessage,
    doChangeEmail,
    doSubmitDNI,
    customerList,
    doSubmitIdentityResolverStep,
    doChangeCustomer,
    prevHash,
    reStartFlow,
    doContinueLoanRequest,
    errorMessage,
    dismissMessage,
  };
};

export default usePreOnboardingServicesAdwordsController;

import parse from 'html-react-parser';
import { Button, Checkbox, Link } from '@doit/pcnt-react-ui-library';
import { useState } from 'react';
import {
  Box, Divider, FormGroup, Grid, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { getEnv, REVOLVING_LINE_ACTIVATION_AGREEMENT_URL } from '../api/env';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: '700',
  },
  avaiableAmountLabel: {
    fontSize: '14px',
    fontWeight: '600',
  },
  amount: {
    color: '#E72064',
    fontSize: '32px',
    fontWeight: '700',
    fontFamily: 'Poppins',
    marginBottom: '8px',
  },
  detailBox: {
    marginTop: '24px',
    padding: '16px',
    backgroundColor: '#F5F5F5',
  },
  text: {
    textAlign: 'left',
    fontSize: '14px',
  },
  bullet: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '16px',
  },
}));

const RevolvingLineSummary = ({
  loanRequest, summaryData, signAgreement, signLoading,
}) => {
  console.log(loanRequest, summaryData, signAgreement, signLoading);
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const getAgreementActions = () => (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <FormGroup className="summary-terms-and-conditions">
          <Checkbox
            label={i18n.SummaryTermsAndConditionsMode.agreement}
            size="small"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </FormGroup>
        <Link
          size="small"
          underline="always"
          className="summary-terms-and-conditions-agreement"
          href={getEnv(REVOLVING_LINE_ACTIVATION_AGREEMENT_URL)}
          target="_blank"
        >
          {i18n.SummaryTermsAndConditionsMode.showAgreement}
        </Link>
      </Box>
      <Box mt={3} width="100%">
        <Button
          variant="primary"
          className="btn-primary"
          fullWidth
          onClick={signAgreement}
          disabled={!checked}
          loading={signLoading}
        >
          {i18n.OnboardingSummary.signCTA}
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <Grid container pt={10} pl={4} pr={4} pb={10} justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={classes.title}>{i18n.RevolvingLineSummary.title}</Typography>
          <Box className={classes.detailBox}>
            <Typography className={classes.avaiableAmountLabel}>{i18n.RevolvingLineSummary.availableAmount}</Typography>
            <Typography className={classes.amount}>{moneyFormatter(loanRequest.requestedAmount)}</Typography>
            <Divider />
            <Typography className={classes.text} sx={{ marginTop: '16px' }}>{i18n.RevolvingLineSummary.youCanDo}</Typography>
            {i18n.RevolvingLineSummary.items.map((item) => (
              <Box className={classes.bullet} key={item}>
                <img src="/assets/images/bullet.svg" alt="item" style={{ marginRight: '8px' }} />
                <Typography className={classes.text}>{parse(item)}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Box className="summary-terms-and-conditions-cta-container">
        <Box pl={2} pr={2} pt={3} pb={3} display="flex" flexDirection="column" alignItems="center">
          {getAgreementActions()}
        </Box>
      </Box>
    </>
  );
};

RevolvingLineSummary.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  summaryData: PropTypes.shape().isRequired,
  signAgreement: PropTypes.func.isRequired,
  signLoading: PropTypes.bool.isRequired,
};

export default RevolvingLineSummary;

import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { Alert, Button, Checkbox } from '@doit/pcnt-react-ui-library';
import { yupResolver } from '@hookform/resolvers/yup';
import { codeSchemaValidation } from '../forms/schemaValidations';
import TextInputWrapper from './commons/TextInputWrapper';
import i18n from '../common/i18n';
import { ORIGINATION_POLICY_URL, ORIGINATION_TERMS_URL, getEnv } from '../api/env';
import { fillMessageWith, sendDataLayerEventObject } from '../utils/functionsUtil';
import NavigationActions from './NavigationActions';

const VerificationCodeStep = ({
  userInputLabel, userInput, onChangeUserInput, onSubmit, loading, onResend, resendMessage, onError, goBack,
}) => {
  const methods = useForm({
    resolver: yupResolver(codeSchemaValidation),
    mode: 'onChange',
  });

  const timeLimit = 60;
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const resendCode = () => {
    setTimeLeft(timeLimit);
    onResend();
  };

  const countDown = () => {
    // Set to "true" when component is mounted
    let mounted = true;
    setTimeout(() => {
      // Check if the component is still mounted
      if (mounted) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
    // Set to "false" when the component is unmounted
    return () => {
      mounted = false;
    };
  };

  const handleSubmit = (data) => {
    const method = userInputLabel === i18n.VerificationCodeStep.methodEmailLabel ? 'email' : 'sms';
    sendDataLayerEventObject({ event: 'validar_preonboarding', tipoValidacion: method });
    onSubmit(data);
  };

  useEffect(() => countDown(), [timeLeft]);

  return (
    <>
      <Typography variant="h1" align="left">
        {fillMessageWith(i18n.VerificationCodeStep.title, userInputLabel)}
      </Typography>
      <Box className="verification-code-container">
        <Typography className="verification-code-label">
          {parse(fillMessageWith(onError ? i18n.VerificationCodeStep.userInputDetailOnError
            : i18n.VerificationCodeStep.userInputDetail, userInputLabel, userInput))}
        </Typography>
        <Button
          variant="tertiary"
          onClick={onChangeUserInput}
        >
          {fillMessageWith(i18n.VerificationCodeStep.cellphoneEditButton, userInputLabel)}
        </Button>
      </Box>
      <Box mt={4} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {!onError && (
            <>
              <Grid container justifySelf="left">
                <TextInputWrapper
                  className="verification-code-input"
                  name="code"
                  label={i18n.VerificationCodeStep.inputLabel}
                  helperText={i18n.VerificationCodeStep.inputHint}
                  inputProps={{ maxLength: 4 }}
                  mandatory
                  fullWidth
                />
              </Grid>
              <Box m={1} />
              {timeLeft >= 1 ? (
                <Typography className="count-down">
                  {`${i18n.VerificationCodeStep.countDown[0]} ${timeLeft} ${i18n.VerificationCodeStep.countDown[1]}`}
                </Typography>
              ) : (
                <Grid container>
                  <Button onClick={resendCode} variant="tertiary" className="count-down-btn">
                    {i18n.VerificationCodeStep.resendButton}
                  </Button>
                </Grid>
              )}
            </>
          )}
          <Box m={3} />
          {resendMessage && (
            <Alert severity="info" className="resend-message">{resendMessage}</Alert>
          )}
          <Box m={3} />
          <Grid container justifySelf="left" className="terms-conditions-container">
            <Checkbox
              className="terms-conditions-check"
              name="terms"
              onChange={handleChange}
            />
            <Typography className="terms-conditions">
              {i18n.VerificationCodeStep.termsAndConditions[0]}
              <Button
                className="terms-conditions-button"
                variant="tertiary"
                onClick={() => window.open(getEnv(ORIGINATION_TERMS_URL))}
              >
                {i18n.VerificationCodeStep.termsAndConditions[1]}
              </Button>
              {i18n.VerificationCodeStep.termsAndConditions[2]}
              <Button
                className="terms-conditions-button"
                variant="tertiary"
                onClick={() => window.open(getEnv(ORIGINATION_POLICY_URL))}
              >
                {i18n.VerificationCodeStep.termsAndConditions[3]}
              </Button>
              {i18n.VerificationCodeStep.termsAndConditions[4]}
              <b>{i18n.VerificationCodeStep.termsAndConditions[5]}</b>
              {i18n.VerificationCodeStep.termsAndConditions[6]}
            </Typography>
          </Grid>
          <Box mt={4} />
          <NavigationActions
            onContinue={methods.handleSubmit(handleSubmit)}
            onBack={goBack}
            loading={loading}
            disabled={!methods.formState.isValid || !checked}
          />
        </form>
      </FormProvider>
    </>
  );
};

VerificationCodeStep.propTypes = {
  userInputLabel: PropTypes.string,
  userInput: PropTypes.string.isRequired,
  onChangeUserInput: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  resendMessage: PropTypes.string,
  onError: PropTypes.bool,
};

VerificationCodeStep.defaultProps = {
  userInputLabel: 'celular',
  resendMessage: undefined,
  onError: false,
};

export default VerificationCodeStep;

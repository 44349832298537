import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, Grid, Typography } from '@mui/material';
import {
  automaticSign,
  getLoanRequest, getPrepaidCardLastFour, getSummaryDataLoan, getUrlForUpload, reminder,
} from '../api/onboardingService';
import Loading from '../components/commons/Loading';
import {
  dataURLtoBlob, getResponseErrorOrDefault, sendDataLayerCustomerInfo, sendDataLayerEvent, sendDataLayerEventObject,
} from '../utils/functionsUtil';
import '../assets/css/onboardingSummary.css';
import i18n from '../common/i18n';
import Toast from '../components/commons/Toast';
import SimpleFooter from '../components/SimpleFooter';
import { loadWebchat } from '../components/webchatComponentScript';
import { CREDICUOTAS_SELFIE_BACKEND_URL, getEnv } from '../api/env';
import { routes } from '../constants/routes';
import errorVerificationImg from '../assets/imgs/error-verification.svg';
import SummarySignatureMode from '../components/SummarySignatureMode';
import SummaryTermsAndConditionsMode from '../components/SummaryTermsAndConditionsMode';
import { isLoanRequestRevolvingLine } from '../utils/loanRequestUtils';
import RevolvingLineSummary from '../components/RevolvingLineSummary';

const OnboardingSummary = () => {
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState();
  const [summaryData, setSummaryData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [signature, setSignature] = useState();
  const [signLoading, setSignLoading] = useState(false);
  const [secondValidationRejected, setSecondValidationRejected] = useState();
  const [prepaidCardLastDigits, setPrepaidCardLastDigits] = useState();

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const getDataLoan = async () => {
    const summaryDataResponse = await getSummaryDataLoan(hash);
    const summary = summaryDataResponse.data;
    setSummaryData(summary);
  };

  const showAgreement = () => {
    window.open(`${getEnv(CREDICUOTAS_SELFIE_BACKEND_URL)}/onboarding/download/${hash}/documentation`, '_blank');
  };

  const sigPadRef = (ref) => {
    if (ref && !ref.isEmpty()) {
      setSignature(ref.toDataURL());
    } else {
      setSignature(undefined);
    }
  };

  const signAgreement = async () => {
    try {
      setSignLoading(true);
      const blob = dataURLtoBlob(signature);
      const file = new File([blob], 'urlDataAsBlob.png');
      await getUrlForUpload(hash, 'userSignature', file);
      sendDataLayerEventObject({ event: 'firma_contrato', idCredito: loanRequest.sappCreditId });
      sendDataLayerEvent('purchase', loanRequest.requestedAmount, loanRequest.sappCreditId);
      navigate({
        pathname: `${routes.OnboardingFinal}/${hash}`,
        search: `?${queryParams.toString()}`,
      });
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setSignLoading(false);
    }
  };

  const signTermsAndConditionsAgreement = async () => {
    try {
      setSignLoading(true);
      await automaticSign(hash);
      sendDataLayerEventObject({ event: 'firma_contrato', idCredito: loanRequest.sappCreditId });
      sendDataLayerEvent('purchase', loanRequest.requestedAmount, loanRequest.sappCreditId);
      navigate({
        pathname: `${routes.OnboardingFinal}/${hash}`,
        search: `?${queryParams.toString()}`,
      });
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setSignLoading(false);
    }
  };

  const getPrepaidCardLastFourDigits = async () => {
    try {
      const lastDigitsResponse = await getPrepaidCardLastFour(hash);
      setPrepaidCardLastDigits(lastDigitsResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      await reminder(hash);
    } catch (error) {
      console.log(error);
    }
    try {
      const loanRequestResponse = await getLoanRequest(hash);
      const loan = loanRequestResponse.data;
      setLoanRequest(loan);
      await getPrepaidCardLastFourDigits();
      sendDataLayerCustomerInfo(loan);
      await getDataLoan();
      // webchat
      loadWebchat(loan);
    } catch (error) {
      if (error?.response?.data?.cause === 'LOAN_SECOND_VALIDATION_WAS_REJECTED') {
        setSecondValidationRejected(true);
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12}>
          {loading && (<Loading />)}
          {!loading && !errorMessage && !secondValidationRejected && (
            <Grid container justifyContent="center">
              {isLoanRequestRevolvingLine(loanRequest) ? (
                <RevolvingLineSummary
                  loanRequest={loanRequest}
                  summaryData={summaryData}
                  signAgreement={signTermsAndConditionsAgreement}
                  signLoading={signLoading}
                />
              ) : (
                <>
                  {summaryData.signatureMode ? (
                    <SummarySignatureMode
                      loanRequest={loanRequest}
                      summaryData={summaryData}
                      prepaidCardLastDigits={prepaidCardLastDigits}
                      sigPadRef={sigPadRef}
                      showAgreement={showAgreement}
                      signAgreement={signAgreement}
                      signature={signature}
                      signLoading={signLoading}
                    />
                  ) : (
                    <SummaryTermsAndConditionsMode
                      loanRequest={loanRequest}
                      summaryData={summaryData}
                      prepaidCardLastDigits={prepaidCardLastDigits}
                      signAgreement={signTermsAndConditionsAgreement}
                      signLoading={signLoading}
                    />
                  )}
                </>
              )}
            </Grid>
          )}
          {!loading && secondValidationRejected && (
            <>
              <img src={errorVerificationImg} alt="error_verification" width="250px" />
              <Box mt={3} />
              <Typography variant="h2">{i18n.OnboardingSummary.secondValidationRejected.title}</Typography>
              <Box mt={2} />
              <Typography variant="h3">{i18n.OnboardingSummary.secondValidationRejected.subtitle}</Typography>
              <Box mt={2} />
              <Typography variant="p">{i18n.OnboardingSummary.secondValidationRejected.description}</Typography>
            </>
          )}
        </Grid>
      </Grid>
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={() => setErrorMessage(undefined)}
        />
      )}
      {!isLoanRequestRevolvingLine(loanRequest) && (
        <Box sx={!summaryData?.signatureMode && { marginBottom: '200px' }}>
          <SimpleFooter subproductId={loanRequest?.subProductId} />
        </Box>
      )}
    </>
  );
};

export default OnboardingSummary;

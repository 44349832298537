import { getEnv, SELF_MANAGEMENT_URL } from '../api/env';

const useNavigation = () => {
  const goToLendingSelfieHome = () => {
    window.location.href = getEnv(SELF_MANAGEMENT_URL);
  };

  const goToRevolvingLine = () => {
    window.location.href = `${getEnv(SELF_MANAGEMENT_URL)}linea-revolvente-detalle`;
  };

  return { goToLendingSelfieHome, goToRevolvingLine };
};

export default useNavigation;

import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Box, Grid } from '@mui/material';
import PreDocumentLoad from '../components/PreDocumentLoad';
import '../assets/css/documentLoad.css';
import SimpleFooter from '../components/SimpleFooter';
import {
  getDocuments, getDocumentsLoaded, getLoanRequest,
  getNextDocumentoToUpload, reminder, saveMobileUserAgent, sendLinkToContinueDocumentsUploadFromMobile,
} from '../api/onboardingService';
import Loading from '../components/commons/Loading';
import PreDocumentLoadRenewer from '../components/PreDocumentLoadRenewer';
import Toast from '../components/commons/Toast';
import { getResponseErrorOrDefault, sendDataLayerCustomerInfo, sendDataLayerEventObject } from '../utils/functionsUtil';
import UploadDocument from '../components/UploadDocument';
import UploadDocumentCompleted from '../components/UploadDocumentCompleted';
import OnboardingHeader from '../components/OnboardingHeader';
import i18n from '../common/i18n';
import BarcodeReadMessage from '../components/BarcodeReadMessage';
import { CREDICUOTAS_WEBSITE_URL, getEnv } from '../api/env';
import { loadWebchat } from '../components/webchatComponentScript';

const OnboardingDocumentLoad = () => {
  const { hash } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);

  const [loading, setLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState();
  const [errorMessage, setErrorMessage] = useState();

  // En base a los documentos que se encuentran cargados y si el cliente es renovador o no
  // se muestra la vista de renovadores con precarga o la vista "normal"
  const [documentLoaded, setDocumentLoaded] = useState();

  // Desde desktop, existe la posibilidad de enviar un sms para continuar desde mobile
  // por eso existe este countDown para mostrar el mensaje con la cuenta regresiva, para
  // permitir reenviar el mensaje en caso de que sea necesario.
  const [showCountDown, setShowCountDown] = useState(false);

  // List de documentos a cargar
  const [requiredDocumentList, setRequiredDocumentList] = useState();

  const [currentDocumentToLoad, setCurrentDocumentToLoad] = useState();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState();
  const [documentLoadCompleted, setDocumentLoadCompleted] = useState();
  const [barcodeReaderMessage, setBarcodeReaderMessage] = useState();

  // Variables para decidir si se va a mostrar o no la vista de renovador o comun
  const isRenewerWithPreLoad = () =>
    !loanRequest.isNewClient
    && documentLoaded.backSideDocument
    && documentLoaded.frontSideDocument
    && documentLoaded.paymentInformation;

  const showUploadDocumentStep = () => currentDocumentToLoad !== undefined && currentDocumentToLoad !== null;

  // Desde desktop cuando se envia el link por sms para continuar con la carga de fotos desde mobile
  const handleSendMessageToContinueMobile = async () => {
    try {
      await sendLinkToContinueDocumentsUploadFromMobile(hash);
      setShowCountDown(true);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  // Obtiene el proximo documento a cargar
  const getNextDocument = async () => {
    try {
      const documentName = currentDocumentToLoad ? currentDocumentToLoad.name : null;
      const response = await getNextDocumentoToUpload(hash, documentName);
      setCurrentDocumentIndex(response.data.currentDocumentNumber);
      setCurrentDocumentToLoad(response.data.variable);
      setDocumentLoadCompleted(response.data.documentsLoaded === response.data.totalDocumentsNumber);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const handlePreLoadDocument = () => {
    sendDataLayerEventObject({ event: 'comenzar_carga_documentos' });
    getNextDocument();
  };

  // Al presionar "continuar" en pantalla de precarga obtiene el documento a cargar
  const handleFileUploadSuccess = () => {
    // Se le setea un valor dummy en memoria al documento recien cargado para que lo muestre
    // en el listado de arriba como documento cargado.
    const requiredDocumentListAux = requiredDocumentList;
    requiredDocumentListAux[currentDocumentIndex - 1].userInputOptions.defaultValue = 'dummyvalue';
    setRequiredDocumentList(requiredDocumentListAux);

    getNextDocument();
  };

  // Maneja el error al intentar subir foto.
  // Identificando las casuisticas de error de barcode
  const handleFileUploadError = (error) => {
    const errorCode = error?.response?.data?.errorCode;
    if (errorCode === 'DOCUMENT_INVALID_DNI_IMAGE') {
      setBarcodeReaderMessage(i18n.BarcodeReadMessage.invalidImage);
    } else if (errorCode === 'DNI_BARCODE_NOT_MATCH') {
      setBarcodeReaderMessage(i18n.BarcodeReadMessage.notMatch);
    } else if (errorCode === 'DNI_BARCODE_NOT_FOUND') {
      setBarcodeReaderMessage(i18n.BarcodeReadMessage.notRead);
    } else if (errorCode === 'DNI_BARCODE_NOT_FOUND_EXCEED_LIMIT') {
      setBarcodeReaderMessage(i18n.BarcodeReadMessage.exceed);
    } else if (errorCode === 'DNI_BARCODE_PHOTO_REPEATED') {
      sendDataLayerEventObject({ event: 'carga_foto_duplicada' });
      setBarcodeReaderMessage(i18n.BarcodeReadMessage.notValidDorso);
    } else if (errorCode === 'ERROR_RENAPER_PERSON_NOT_ACTIVE') {
      setBarcodeReaderMessage(i18n.RenaperReadMessage.notActive);
    } else if (errorCode === 'ERROR_RENAPER_PERSON_IS_DEATH') {
      setBarcodeReaderMessage(i18n.RenaperReadMessage.isDeath);
    } else {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  // CTA de la pantalla de error de barcode, manejando las distintas casuisticas
  const handleBarcodeError = () => {
    if (barcodeReaderMessage.id === 'DNI_BARCODE_NOT_FOUND'
        || barcodeReaderMessage.id === 'DNI_BARCODE_NOT_FOUND_EXCEED_LIMIT'
        || barcodeReaderMessage.id === 'DNI_BARCODE_PHOTO_REPEATED'
        || barcodeReaderMessage.id === 'DOCUMENT_INVALID_DNI_IMAGE'
        || barcodeReaderMessage.id === 'ERROR_RENAPER_PERSON_NOT_ACTIVE'
        || barcodeReaderMessage.id === 'ERROR_RENAPER_PERSON_IS_DEATH') {
      window.location.reload();
    } else if (barcodeReaderMessage.id === 'DNI_BARCODE_NOT_MATCH') {
      window.location = getEnv(CREDICUOTAS_WEBSITE_URL);
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      saveMobileUserAgent(hash, window.navigator.userAgent);
      await reminder(hash);
    } catch (error) {
      console.log(error);
    }
    try {
      const loanRequestResponse = await getLoanRequest(hash);
      setLoanRequest(loanRequestResponse.data);

      sendDataLayerCustomerInfo(loanRequestResponse.data);

      // Nuevo endpoint que se utiliza para decidir si se debe mostrar la vista de renovadores o no.
      // Se hizo para reemplazar un monton de logica de front que estaba en angular.
      const documentLoadedResponse = await getDocumentsLoaded(hash);
      setDocumentLoaded(documentLoadedResponse.data);

      const requiredDocumentsResponse = await getDocuments(hash);
      setRequiredDocumentList(requiredDocumentsResponse.data);

      if (queryParams.get('e') === '1') {
        // Skipea pantalla de pre carga
        getNextDocument();
      }

      loadWebchat(loanRequest);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Box className="main-container-body" mb={5}>
        <Grid container className="pre-onboarding-main-container" display="flex" justifyContent="center">
          <Grid item xs={11}>
            {loading ? (
              <Loading />
            ) : (
              <>
                <OnboardingHeader loan={loanRequest} />
                <Box mt={3} />
                {/* PANTALLA DE PRE CARGA DE IMAGENES PARA RENOVADORES */}
                {isRenewerWithPreLoad() && !showUploadDocumentStep() && !documentLoadCompleted && (
                  <PreDocumentLoadRenewer
                    loan={loanRequest}
                    onSendMessage={handleSendMessageToContinueMobile}
                    showCountDown={showCountDown}
                    onCountDown={() => setShowCountDown(false)}
                    onContinue={handlePreLoadDocument}
                  />
                )}
                {/* PANTALLA DE PRE CARGA DE IMAGENES NORMAL O PARA RENOVADORES SIN PRE CARGA DE DOCUMENTOS */}
                {!isRenewerWithPreLoad() && !showUploadDocumentStep() && !documentLoadCompleted && (
                  <PreDocumentLoad
                    loan={loanRequest}
                    onSendMessage={handleSendMessageToContinueMobile}
                    showCountDown={showCountDown}
                    onCountDown={() => setShowCountDown(false)}
                    onContinue={handlePreLoadDocument}
                  />
                )}
                {/* PANTALLA DE CARGA DE FOTOS */}
                {showUploadDocumentStep() && !documentLoadCompleted && !barcodeReaderMessage && (
                  <UploadDocument
                    key={currentDocumentToLoad?.name}
                    loanRequest={loanRequest}
                    document={currentDocumentToLoad}
                    requiredDocumentList={requiredDocumentList}
                    onSuccess={handleFileUploadSuccess}
                    onError={handleFileUploadError}
                  />
                )}
                {/* PANTALLA DE CARGA DE FOTOS COMPLETADA */}
                {documentLoadCompleted && !barcodeReaderMessage && (
                  <UploadDocumentCompleted
                    loan={loanRequest}
                    requiredDocumentList={requiredDocumentList}
                  />
                )}
                {/* PANTALLA DE MENSAJE DE ERROR DE BARCODE */}
                {barcodeReaderMessage && (
                  <BarcodeReadMessage barcodeMessage={barcodeReaderMessage} onCTA={handleBarcodeError} />
                )}
              </>
            )}
          </Grid>
        </Grid>
        {errorMessage && (
          <Toast
            messageOnError={errorMessage}
            open
            onClose={() => setErrorMessage(undefined)}
          />
        )}
      </Box>
      <SimpleFooter subproductId={loanRequest?.subProductId} />
    </>
  );
};

export default OnboardingDocumentLoad;

import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
import i18n from '../common/i18n';
import '../assets/css/simpleFooter.css';

const SimpleFooter = ({ subproductId }) => {
  const getFooter = () => {
    let footer = i18n.SimpleFooter.description;
    const messageByProduct = i18n.SimpleFooter.subproduct[subproductId];
    if (messageByProduct) {
      footer = messageByProduct;
    }
    return footer;
  };

  return (
    <Box className="simple-footer-box">
      <Typography className="simple-footer-description">{parse(getFooter())}</Typography>
    </Box>
  );
};

SimpleFooter.propTypes = {
  subproductId: PropTypes.string,
};

SimpleFooter.defaultProps = {
  subproductId: undefined,
};

export default SimpleFooter;

'use client';

import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import Theme from './assets/theme/theme';
import AppRouter from './navigation/AppRouter';
import { ToasterProvider } from './contexts/ToasterContext';
import Toast from './components/commons/Toast';
import { routes } from './constants/routes';
import { logClientError } from './api/onboardingService';

function App() {
  const ConsoleErrorFallbackComponent = () => (
    <Toast
      open
      messageOnError="Ocurrió un error"
      onClose={() => { window.location.href = routes.preOnboardingPersonalLoan; }}
    />
  );

  const notifyError = (error, info) => {
    try {
      console.log('*************************************************');
      const { userAgent } = window.navigator;
      const url = window.location.href;
      const stackTrace = info.componentStack;
      const message = `\nCLIENT_CONSOLE_ERROR: ${error} \n- USER_AGENT: ${userAgent} \n- URL: ${url} \n- STACK_TRACE: ${stackTrace}`;
      console.log(message);
      logClientError(message);
      console.log('*************************************************');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ConsoleErrorFallbackComponent} onError={notifyError}>
      <div className="App">
        <ThemeProvider theme={Theme}>
          <ToasterProvider>
            <AppRouter />
          </ToasterProvider>
        </ThemeProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;

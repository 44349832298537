import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import Footer, { FooterType } from '../components/commons/Footer';
import i18n from '../common/i18n';
import { onePageSchemaValidation } from '../forms/schemaValidations';
import TextInputWrapper from '../components/commons/TextInputWrapper';
import {
  checkBlacklist, checkExistingLoanRequest, resolveCustomers,
  savePreSimulationLoan, sendVerificationCode, startWorkflow, updateCustomerData,
  updateVerifiedCodeAndEmail, verifyCode, verifyEmail,
  verifyEmailDomain,
} from '../api/onboardingService';
import {
  getResponseErrorOrDefault, fillMessageWith, sendDataLayerEventObject, isMobileOperatingSystem,
} from '../utils/functionsUtil';
import IdentityResolverStep from '../components/IdentityResolverStep';
import VerificationCodeStep from '../components/VerificationCodeStep';
import Toast from '../components/commons/Toast';
import { ERROR_CODES } from '../utils/errorCodeConstant';
import { routes } from '../constants/routes';
import CalculatorLoading from '../components/commons/CalculatorLoading';
import {
  redirectToContinueURLSlashM, saveUrlSourceParameters,
  dniIncludesLastDigit, resolveSiteBanner,
} from '../utils/commonServices';
import ContinueLoanRequestModal from '../components/ContinueLoanRequestModal';
import PreOnboardingNextSteps from '../components/PreOnboardingNextSteps';
import { loanRedirect } from '../components/LoanRequestRedirectService';
import PreOnboardinLoadData from '../components/PreOnboardingLoadData';

const PreOnboardingOnePage = () => {
  const steps = {
    init: 'init',
    resolveCustomer: 'resolveCustomer',
    verificationCode: 'verificationCode',
  };

  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps.init);
  const [customerList, setCustomerList] = useState();
  const [customerSelected, setCustomerSelected] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerCellphone, setCustomerCellphone] = useState();
  const [resendMessage, setResendMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [calculatingOffer, setCalculatingOffer] = useState(false);
  const [prevHash, setPrevHash] = useState();
  const [banner, setBanner] = useState();
  const [verificationCodeMethodEmail, setVerificationCodeMethodEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const methods = useForm({
    resolver: yupResolver(onePageSchemaValidation),
    mode: 'onChange',
  });

  const resolveCustomer = async (dni) => {
    try {
      const response = await resolveCustomers(dni);
      setCustomerList(response.data);
      setCurrentStep(steps.resolveCustomer);
      window.scrollTo(0, 0);
      setVerificationCodeMethodEmail(dniIncludesLastDigit(dni));
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const sendCode = async (cellphone, email, resendCode, customer) => {
    try {
      const verificationInputValue = verificationCodeMethodEmail ? email : cellphone;
      sendDataLayerEventObject({ event: 'enviar_codigo', tipoValidacion: verificationCodeMethodEmail ? 'EMAIL' : 'SMS' });
      await sendVerificationCode(verificationInputValue, cellphone, email, resendCode);
      if (!resendCode) {
        await savePreSimulationLoan(cellphone, email, customer, 'personal-full');
      }
      setCurrentStep(steps.verificationCode);
      window.scrollTo(0, 0);
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_EMAIL_BELONG_TO_OTHER_CUSTOMER) {
        throw error;
      } else if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_SENDING_SMS_BUT_EMAIL_SENT) {
        setResendMessage(`${i18n.VerificationCodeStep.resendMessageJustMail} ${customerEmail}`);
      } else if (error?.response?.data?.errorCode === ERROR_CODES.ERROR_SENDING_EMAIL) {
        setResendMessage(`${i18n.VerificationCodeStep.resendMessageErrorMail}`);
        setInvalidEmail(true);
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const reStartFlow = () => {
    setPrevHash(undefined);
    setCustomerSelected(undefined);
    setCustomerEmail(undefined);
    setCustomerCellphone(undefined);
    setCurrentStep(steps.init);
    window.scrollTo(0, 0);
  };

  const checkBlackListAndSendVerificationCode = async (cellphone, email, customer) => {
    try {
      setLoading(true);
      await checkBlacklist(cellphone);
      setCustomerCellphone(cellphone);
      setCustomerEmail(email);
      await sendCode(cellphone, email, false, customer);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
      reStartFlow();
    } finally {
      setLoading(false);
    }
  };

  const updateCustomerDataAndSendCode = async (customer, email, cellphone) => {
    try {
      setLoading(true);
      await updateCustomerData(customer, email, 56);

      await checkBlackListAndSendVerificationCode(cellphone, email, customer);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleOnSubmit = async (data) => {
    try {
      setLoading(true);
      await verifyEmailDomain(data.email);
      sendDataLayerEventObject({ event: 'validar_dni' });
      sendDataLayerEventObject({ event: 'validar_email' });
      if (customerSelected) {
        // Si ya tenia el customer resuelto, lo envio directo a verificacion de codigo sms
        await updateCustomerDataAndSendCode(customerSelected, data.email, data.cellphone);
      } else {
        // Si no tenia el customer resulto, lo envio a la seleccion de cuit
        await resolveCustomer(data.dni);
        setCustomerCellphone(data.cellphone);
      }
      setCustomerEmail(data.email);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitIdentityResolverStep = (customer) => {
    setLoading(true);
    setCustomerSelected(customer);
    setLoading(false);
    updateCustomerDataAndSendCode(customer, customerEmail, customerCellphone);
  };

  const handleChangeCustomer = () => {
    setCustomerSelected(undefined);
    setCustomerList(undefined);
    setCurrentStep(steps.init);
    window.scrollTo(0, 0);
  };

  const handleChangeEmail = () => {
    setCustomerEmail(undefined);
    setCurrentStep(steps.init);
    window.scrollTo(0, 0);
  };

  const handleChangeCellphone = () => {
    setCustomerCellphone(undefined);
    setCurrentStep(steps.init);
    window.scrollTo(0, 0);
  };

  const resendVerificationCode = () => {
    sendCode(customerCellphone, customerEmail, true);
    setResendMessage(
      fillMessageWith(i18n.VerificationCodeStep.resendMessage, (verificationCodeMethodEmail
        ? i18n.VerificationCodeStep.methodEmailLabel
        : i18n.VerificationCodeStep.methodSmsLabel)),
    );
  };

  const dismissMessage = () => {
    setErrorMessage(undefined);
  };

  const startWorkflowProcess = async () => {
    try {
      setCalculatingOffer(true);
      window.scrollTo(0, 0);
      const res = await startWorkflow();
      if (!verificationCodeMethodEmail) {
        await verifyEmail(res.data.hashKey);
      }
      saveUrlSourceParameters(res.data.hashKey);
      loanRedirect(res.data, navigate, queryParams.toString());
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE) {
        navigate({
          pathname: `${routes.OnboardingErrorCode}/${ERROR_CODES.SERVICE_UNAVAILABLE_FOR_MAINTENANCE}`,
          search: `?${queryParams.toString()}`,
        });
      } else {
        setErrorMessage(getResponseErrorOrDefault(error));
      }
    }
  };

  const initWorkflow = async () => {
    try {
      const res = await checkExistingLoanRequest(customerSelected.cuit);
      setPrevHash(res.data.hashKey);
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.SAPP_NO_PEN_CREDITS) {
        startWorkflowProcess();
      } else {
        setErrorMessage(i18n.unexpectedErrorMessage);
      }
    }
  };

  const handleSubmitVerificationCode = async ({ code }) => {
    try {
      setLoading(true);
      const data = {
        userInputName: 'WT_PHONE',
        userInputVerificationCode: code,
        userInputValue: verificationCodeMethodEmail
          ? customerEmail
          : customerCellphone,
      };
      const res = await verifyCode(data);
      if (res.data.result) {
        initWorkflow();
      } else {
        setErrorMessage(i18n.VerificationCodeStep.errors.invalidCode);
      }
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const handleContinueLoanRequest = async () => {
    try {
      await updateVerifiedCodeAndEmail(customerCellphone, customerEmail, prevHash);
      redirectToContinueURLSlashM(prevHash);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    }
  };

  const init = async () => {
    const bannerSrc = await resolveSiteBanner();
    setBanner(bannerSrc);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {!calculatingOffer && (
        <>
          <Grid container className="preonboarding">
            <Grid item md={6} xs={12} className="preonboarding-flow">
              {/* ********** Muestra los datos que se fueron completando ********** */}
              <PreOnboardinLoadData
                customer={customerSelected}
                phone={customerCellphone}
                email={customerEmail}
              />
              <Box mt={4} />
              {/* ********** Titulo y subtitulo ********** */}
              {currentStep === steps.init && (
                <>
                  <Typography className="pre-onboarding-title" component="h1">
                    {i18n.PreOnboardingOnePage.title}
                  </Typography>
                  <Typography className="pre-onboarding-subtitle" component="h3">
                    {i18n.PreOnboardingOnePage.subtitle}
                  </Typography>
                  <Box mt={4} />
                </>
              )}
              {/* ********** Formulario carga de datos inicial ********** */}
              {currentStep === steps.init && (
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                    <Grid container justifySelf="left">
                      {customerSelected === undefined && (
                        <>
                          <TextInputWrapper
                            name="dni"
                            label={i18n.PreOnboardingOnePage.inputDNILabel}
                            helperText={i18n.PreOnboardingOnePage.inputDNIHint}
                            inputProps={{ maxLength: 9 }}
                            mandatory
                            fullWidth
                          />
                          <Box mt={10} />
                        </>
                      )}
                      {customerEmail === undefined && (
                        <>
                          <TextInputWrapper
                            name="email"
                            label={i18n.PreOnboardingOnePage.inputEmailLabel}
                            helperText={i18n.PreOnboardingOnePage.inputEmailHint}
                            mandatory
                            fullWidth
                          />
                          <Box mt={10} />
                        </>
                      )}
                      {customerCellphone === undefined && (
                        <TextInputWrapper
                          name="cellphone"
                          label={i18n.PreOnboardingOnePage.inputCellphoneLabel}
                          helperText={i18n.PreOnboardingOnePage.inputCellphoneHint}
                          inputProps={{ maxLength: 12 }}
                          mandatory
                          fullWidth
                        />
                      )}
                      <Box mt={15} />
                      <Button
                        variant="primary"
                        className="btn-primary"
                        type="submit"
                        disabled={!methods.formState.isValid}
                        fullWidth
                        loading={loading}
                      >
                        {i18n.PreOnboardingOnePage.buttonLabel}
                      </Button>
                    </Grid>
                  </form>
                </FormProvider>
              )}
              {/* ********** Seleccion de CUIT ********** */}
              {currentStep === steps.resolveCustomer && (
                <IdentityResolverStep
                  customers={customerList}
                  onSubmit={handleSubmitIdentityResolverStep}
                  goBack={handleChangeCustomer}
                  loading={loading}
                />
              )}
              {/* ********** Verificacion codigo SMS ********** */}
              {currentStep === steps.verificationCode && (
                <VerificationCodeStep
                  userInputLabel={
                    verificationCodeMethodEmail
                      ? i18n.VerificationCodeStep.methodEmailLabel
                      : i18n.VerificationCodeStep.methodSmsLabel
                  }
                  userInput={verificationCodeMethodEmail ? customerEmail : customerCellphone}
                  onChangeUserInput={verificationCodeMethodEmail ? handleChangeEmail : handleChangeCellphone}
                  onResend={resendVerificationCode}
                  onSubmit={handleSubmitVerificationCode}
                  loading={loading}
                  resendMessage={resendMessage}
                  onError={invalidEmail}
                  goBack={handleChangeCustomer}
                />
              )}
            </Grid>
            {!isMobileOperatingSystem() && (
              <Grid item md={6}>
                <img src={banner} alt="Credicuotas Banner" width="100%" />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {/* ********** Loading calculadora ********** */}
      {calculatingOffer && (
        <>
          <CalculatorLoading />
          <Box mt={4} />
        </>
      )}
      {/* ********** Modal de credito pendiente o finalizado ********** */}
      {prevHash && (
        <ContinueLoanRequestModal
          hash={prevHash}
          onClose={() => reStartFlow()}
          onContinue={handleContinueLoanRequest}
        />
      )}
      {/* ********** Toast con mensajes de error ********** */}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={dismissMessage}
        />
      )}
      {/* ********** Proximos pasos ********** */}
      <PreOnboardingNextSteps />
      <Footer type={FooterType.DEFAULT} />
    </>
  );
};

export default PreOnboardingOnePage;

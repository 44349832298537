import { useEffect } from 'react';
import {
  Box, Grid, Hidden, Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@doit/pcnt-react-ui-library';
import { yupResolver } from '@hookform/resolvers/yup';
import CalculatorLoading from '../components/commons/CalculatorLoading';
import usePreOnboardingServicesAdwordsController from '../hooks/controllers/usePreOnboardingServicesAdwordsController';
import Footer, { FooterType } from '../components/commons/Footer';
import PreOnboardinLoadData from '../components/PreOnboardingLoadData';
import i18n from '../common/i18n';
import TextInputWrapper from '../components/commons/TextInputWrapper';
import { adwardsSchemaValidation } from '../forms/schemaValidations';
import banner from '../assets/imgs/banner-services.png';
import VerificationCodeStep from '../components/VerificationCodeStep';
import DNIStep from '../components/DNIStep';
import IdentityResolverStep from '../components/IdentityResolverStep';
import ContinueLoanRequestModal from '../components/ContinueLoanRequestModal';
import Toast from '../components/commons/Toast';
import PreOnboardingNextSteps from '../components/PreOnboardingNextSteps';

const PreOnboardingServicesAdwords = () => {
  const methods = useForm({
    resolver: yupResolver(adwardsSchemaValidation),
    mode: 'onChange',
  });

  const {
    steps,
    calculatingOffer,
    loading,
    customerCellphone,
    customerEmail,
    currentStep,
    setCurrentStep,
    doSubmitContactInfo,
    doChangeCellphone,
    resendVerificationCode,
    doSubmitVerificationCode,
    resendMessage,
    doChangeEmail,
    doSubmitDNI,
    customerList,
    doSubmitIdentityResolverStep,
    doChangeCustomer,
    prevHash,
    reStartFlow,
    doContinueLoanRequest,
    errorMessage,
    dismissMessage,
  } = usePreOnboardingServicesAdwordsController();

  const init = () => {
    setCurrentStep(steps.init);
  };

  useEffect(init, []);

  /* ********** Loading calculadora ********** */
  if (calculatingOffer) {
    return (
      <>
        <CalculatorLoading />
        <Box mt={4} />
        <Footer type={FooterType.SERVICES} />
      </>
    );
  }

  return (
    <>
      <Grid container className="preonboarding">
        <Grid item md={6} xs={12} className="preonboarding-flow">
          {/* ********** Muestra los datos que se fueron completando ********** */}
          <PreOnboardinLoadData
            phone={customerCellphone}
            email={customerEmail}
          />
          {/* ********** Titulo y subtitulo ********** */}
          {currentStep === steps.init && (
            <>
              <Typography className="pre-onboarding-title" component="h1">
                {i18n.PreOnboardingServicesLoan.title}
              </Typography>
              <Typography className="pre-onboarding-subtitle" component="h3">
                {i18n.PreOnboardingServicesLoan.subtitle}
              </Typography>
              <Box mt={4} />
            </>
          )}
          {/* ********** Formulario carga de datos inicial ********** */}
          {currentStep === steps.init && (
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(doSubmitContactInfo)}>
                <Grid container justifySelf="left">
                  {customerEmail === undefined && (
                    <>
                      <TextInputWrapper
                        name="email"
                        label={i18n.PreOnboardingAdwords.inputEmailLabel}
                        helperText={i18n.PreOnboardingAdwords.inputEmailHint}
                        mandatory
                        fullWidth
                      />
                      <Box mt={10} />
                    </>
                  )}
                  {customerCellphone === undefined && (
                    <TextInputWrapper
                      name="cellphone"
                      label={i18n.PreOnboardingAdwords.inputCellphoneLabel}
                      helperText={i18n.PreOnboardingAdwords.inputCellphoneHint}
                      inputProps={{ maxLength: 12 }}
                      mandatory
                      fullWidth
                    />
                  )}
                  <Box mt={15} />
                  <Button
                    variant="primary"
                    className="btn-primary"
                    type="submit"
                    disabled={!methods.formState.isValid}
                    fullWidth
                    loading={loading}
                  >
                    {i18n.PreOnboardingAdwords.buttonLabel}
                  </Button>
                </Grid>
              </form>
            </FormProvider>
          )}
          {/* ********** Verificacion codigo SMS ********** */}
          {currentStep === steps.verificationCode && (
            <VerificationCodeStep
              userInputLabel={i18n.VerificationCodeStep.methodSmsLabel}
              userInput={customerCellphone}
              onChangeUserInput={doChangeCellphone}
              onResend={resendVerificationCode}
              onSubmit={doSubmitVerificationCode}
              loading={loading}
              resendMessage={resendMessage}
              goBack={doChangeEmail}
            />
          )}
          {/* ********** Ingreso de DNI ********** */}
          {currentStep === steps.dni && (
            <DNIStep onSubmit={doSubmitDNI} loading={loading} />
          )}
          {currentStep === steps.resolveCustomer && (
            <IdentityResolverStep
              customers={customerList}
              onSubmit={doSubmitIdentityResolverStep}
              goBack={doChangeCustomer}
              loading={loading}
            />
          )}
        </Grid>
        <Hidden mdDown>
          <Grid item md={6}>
            <img src={banner} alt="Credicuotas Banner" width="100%" />
          </Grid>
        </Hidden>
      </Grid>
      {/* ********** Modal de credito pendiente o finalizado ********** */}
      {prevHash && (
        <ContinueLoanRequestModal
          hash={prevHash}
          onClose={() => reStartFlow()}
          onContinue={doContinueLoanRequest}
        />
      )}
      {/* ********** Toast con mensajes de error ********** */}
      {errorMessage && (
        <Toast
          messageOnError={errorMessage}
          open
          onClose={dismissMessage}
        />
      )}
      {/* ********** Proximos pasos ********** */}
      <PreOnboardingNextSteps />
      <Footer type={FooterType.DEFAULT} />
    </>
  );
};

export default PreOnboardingServicesAdwords;

import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  Button, Img, TextInput,
} from '@doit/pcnt-react-ui-library';
import {
  getServicesCompanies, getPaymentModes,
} from '../api/onboardingService';
import imgMagnifyingGlassDisabled from '../assets/imgs/magnifying-glass-gray.svg';
import imgMagnifyingGlass from '../assets/imgs/magnifying-glass.svg';
import imgExclamation from '../assets/imgs/Exclamation.svg';
import i18n from '../common/i18n';
import { getResponseErrorOrDefault, sendDataLayer } from '../utils/functionsUtil';
import '../assets/css/payServices.css';

/* eslint arrow-body-style:off */
const PayServicesBusinessSearch = ({
  loan,
  setSelectedCompanyList,
  setSelectedServiceCompany,
  setErrorMessage,
  cleanServiceCompanyInit,
}) => {
  const info = i18n.PRODUCT_LOAD.PAY_SERVICE;
  const [loading, setLoading] = useState();
  const [serviceToPay, setServiceToPay] = useState();
  const [servicesCompaniesList, setServicesCompaniesList] = useState();
  const [notServiceFound, setNotServiceFound] = useState();

  const cleanServiceCompany = () => { // changeService()
    cleanServiceCompanyInit();
    setNotServiceFound();
    setServicesCompaniesList();
  };

  const searchServiceToPay = async () => {
    cleanServiceCompany();
    try {
      setLoading(true);
      sendDataLayer({
        event: 'cargar_empresa',
      });
      const res = await getServicesCompanies(loan.hashKey, serviceToPay);
      setServicesCompaniesList(res.data);
      setNotServiceFound(res.data?.length === 0);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
    } finally {
      setLoading(false);
    }
  };

  const setSelectedServiceCompanyInit = async (serviceCompany) => {
    try {
      setLoading(true);
      sendDataLayer({
        event: 'seleccion_empresa',
        value: serviceCompany.name,
      });
      const res = await getPaymentModes(loan.hashKey, serviceCompany.code);
      const selectedServiceCompanyTemp = {
        name: serviceCompany?.name,
        code: serviceCompany?.code,
        listCompanies: res.data,
      };
      const selectedCompanyListTemp = selectedServiceCompanyTemp.listCompanies?.filter((method) => {
        return method?.paymentType === 'CSF' || method?.paymentType === 'CAB' || method?.paymentType === 'SFM'
          || method?.paymentType === 'CLR';
      });
      if (selectedCompanyListTemp.length <= 0) {
        const error = { responses: { data: { cause: info.notServiceAvailable } } };
        throw (error);
      }
      setSelectedServiceCompany(selectedServiceCompanyTemp);
      setSelectedCompanyList(selectedCompanyListTemp);
    } catch (error) {
      setErrorMessage(getResponseErrorOrDefault(error));
      setSelectedServiceCompany();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography className="services-search-hint">{info.searchHint}</Typography>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                placeholder={info.searchInputPlaceHolder}
                onChange={(event) => setServiceToPay(event.target.value)}
                value={serviceToPay}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="primary"
                className="btn-primary"
                type="button"
                fullWidth
                disabled={!serviceToPay}
                onClick={searchServiceToPay}
                loading={loading}
                loadingProps={{
                  size: 20,
                  sx: { marginRight: '12px' },
                }}
              >
                {!loading && (
                <>
                  <Img src={!serviceToPay ? imgMagnifyingGlassDisabled : imgMagnifyingGlass} />
                  <Typography className="services-search-button">{info.searchButton}</Typography>
                </>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {servicesCompaniesList?.length > 0 && (
        <Grid item>
          <Box mt={4} />
          {servicesCompaniesList.map((serviceCompany) => (
            <Grid
              key={serviceCompany.code}
              className="services-card"
              onClick={() => setSelectedServiceCompanyInit(serviceCompany)}
            >
              {serviceCompany.name}
            </Grid>
          ))}
        </Grid>
      )}
      {notServiceFound && (
        <Grid item className="services-warning">
          <Img
            src={imgExclamation}
            alt={info.notServiceFoundImgAlt}
          />
          <Typography>{info.notServiceFound}</Typography>
        </Grid>
      )}
    </>
  );
};

PayServicesBusinessSearch.propTypes = {
  loan: PropTypes.shape(),
  setSelectedCompanyList: PropTypes.func,
  setSelectedServiceCompany: PropTypes.func,
  setErrorMessage: PropTypes.func,
  cleanServiceCompanyInit: PropTypes.func,

};

PayServicesBusinessSearch.defaultProps = {
  loan: undefined,
  setSelectedCompanyList: undefined,
  setSelectedServiceCompany: undefined,
  setErrorMessage: undefined,
  cleanServiceCompanyInit: undefined,
};

export default PayServicesBusinessSearch;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, TextField } from '@mui/material';
import { Button, Alert } from '@doit/pcnt-react-ui-library';
import Cards from 'react-credit-cards';
import '../assets/css/onboardingCustomer.css';
import '../assets/css/creditCard.css';
import logoWhiteAmex from '../assets/imgs/logos/cards/logo_white_amex.svg';
import logoWhiteCabal from '../assets/imgs/logos/cards/logo_white_cabal.svg';
import logoWhiteMaestro3 from '../assets/imgs/logos/cards/logo_white_maestro3.svg';
import logoWhiteMastercard from '../assets/imgs/logos/cards/logo_white_mastercard.svg';
import logoWhiteUnknown from '../assets/imgs/logos/cards/logo_white_unknown.svg';
import logoWhiteVisa from '../assets/imgs/logos/cards/logo_white_visa.svg';
import i18n from '../common/i18n';
import { sendDataLayerEventObject } from '../utils/functionsUtil';
import {
  formatExpirationDate, formatCVC, formatCardNumber, formatName,
} from '../utils/cardUtils';
import { validateDebitCard } from '../api/onboardingService';
import { isLoanRequestRevolvingLine } from '../utils/loanRequestUtils';

const CustomerDataInputCard = ({
  variable, setShowContinueBtn,
  resolveCardinalAssistance, addPaymentModeVoluntary, dc, setDC, loanRequest,
}) => {
  const info = i18n.OnboardingCustomerData.inputCard;
  const [hasValueLoaded, setHasValueLoaded] = useState(false);
  const [showMessageInvalidCardLoaded, setShowMessageInvalidCardLoaded] = useState(false);
  const [showMessageCardLoaded, setShowMessageCardLoaded] = useState(false);
  const [validateDebitCardMessage, setValidateDebitCardMessage] = useState(false);
  const [validateDebitCardSuccessfull, setValidateDebitCardSuccessfull] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [cardLogo, setCardLogo] = useState(undefined);
  const stateInit = {
    debitCardNumber: '',
    customerName: '',
    expirationDate: '',
    cvvCode: '',
    focus: '',
  };
  const [state, setState] = useState(stateInit);

  const getCardLogoByType = (type) => {
    switch (type) {
      case 'amex': return logoWhiteAmex;
      case 'cabal': return logoWhiteCabal;
      case 'maestro3': return logoWhiteMaestro3;
      case 'mastercard': return logoWhiteMastercard;
      case 'unknown': return logoWhiteUnknown;
      case 'visa': return logoWhiteVisa;
      default: return logoWhiteUnknown;
    }
  };

  const showCardToLoad = () => {
    // eslint-disable-next-line no-param-reassign
    variable.userInputOptions.defaultValue = undefined;
    setShowMessageCardLoaded(false);
    setHasValueLoaded(false);
    setShowContinueBtn(false);
    setDC({ number: '', type: 'unknown', mask: '999999999' });
    setState(stateInit);
  };

  const applyMask = (field, value) => {
    switch (field) {
      case 'debitCardNumber': return formatCardNumber(value);
      case 'customerName': return formatName(value).toUpperCase();
      case 'expirationDate': return formatExpirationDate(value);
      case 'cvvCode': return formatCVC(value);
      default: return value;
    }
  };

  const handleInputFocus = (e) => {
    let value = e.target.name;
    switch (e.target.name) {
      case 'debitCardNumber':
        value = 'number';
        break;
      case 'customerName':
        value = 'name';
        break;
      case 'expirationDate':
        value = 'expiry';
        break;
      case 'cvvCode':
        value = 'cvc';
        break;
      default:
        break;
    }
    setState((prev) => ({ ...prev, focus: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const maskedValue = applyMask(name, value);
    setState((prev) => ({ ...prev, [name]: maskedValue }));
  };

  const isExpiryDateError = (expirationDate) => {
    let hasError = false;
    if (expirationDate.trim().length < 5) {
      hasError = true;
    } else {
      const month = expirationDate.trim().split('/')[0];
      if (month < 1 || month > 12) {
        hasError = true;
      }
      const year = expirationDate.trim().split('/')[1];
      const currentYear = new Date().getFullYear().toString().substr(-2);
      if (year < currentYear) {
        hasError = true;
      }
      const currentMonth = new Date().getMonth() + 1;
      if (year === currentYear && month <= currentMonth) {
        hasError = true;
      }
    }
    return hasError;
  };

  /* eslint-disable max-len */
  const sendValidation = async () => {
    const debitCardDTO = {
      customerName: state.customerName,
      cardNumber: state.debitCardNumber.replace(/\s/g, ''),
      expirationMonth: state.expirationDate.split('/')[0],
      expirationYear: state.expirationDate.split('/')[1],
      cvvCode: state.cvvCode,
    };

    setValidateDebitCardSuccessfull(undefined);
    setValidateDebitCardMessage(undefined);

    sendDataLayerEventObject({ event: 'validar_tarjeta' });

    setValidateLoading(true);
    try {
      const response = await validateDebitCard(loanRequest.hashKey, debitCardDTO);

      if (response.data.valid) {
        // response caso éxito para el negocio
        setValidateDebitCardSuccessfull(true);
        setShowContinueBtn(true);

        if (response.data.code === '0') {
        // validacion OK
          setValidateDebitCardMessage(info.debitCardMessageWhenCardValidated);
        } else {
        // fondos insuficientes
          setValidateDebitCardMessage(info.debitCardMessageWhenCardRegistered);
        }

        sendDataLayerEventObject({
          event: 'begin_checkout',
          currency: 'ARS',
          value: loanRequest.requestedAmount,
          ecommerce: {
            items: [{
              item_name: 'Tradicional Web',
              item_id: loanRequest.sappCreditId,
              affiliation: '',
              price: loanRequest.requestedAmount,
            }],
          },
        });

        resolveCardinalAssistance();
      } else {
        // response caso no exitoso para el negocio
        setValidateDebitCardSuccessfull(false);
        setShowContinueBtn(false);

        if (response.data.code === '1' && response.data.requirePhoto) {
          // tarjeta rejected pero puede cargar fotos
          setShowContinueBtn(true);
          setValidateDebitCardMessage(info.debitCardMessageWhenCardNotRegisteredButCanLoadPhotos);
        } else if (response.data.code === '2') {
          // tarjeta fondos insuficientes
          setValidateDebitCardMessage(info.debitCardMessageWhenInsufficientFunds);
        } else if (response.data.code === '3') {
          // tarjeta usada en otra persona
          setValidateDebitCardMessage(info.debitCardMessageWhenCardUsedByOtherPerson);
        } else if (response.data.offerPaymentMethodVoluntary) {
          // se agrega y ofrece forma de pago voluntario si no lo tenia entre las opciones
          addPaymentModeVoluntary();
          setValidateDebitCardMessage(info.debitCardMessageWhenErrorButCanUseOtherPaymentMethods);
        } else if (response.data.code === '5') {
          // Maxima cantidad de intentos
          setValidateDebitCardMessage(info.debitCardMessageWhenErrorCardMaxAttemptsReached);
        } else {
          // error no tipificado
          setValidateDebitCardMessage(info.debitCardMessageWhenGenericError);
        }
      }
    } catch (error) {
      console.log(error);

      // no se ejecutó correctamente la validacion
      setValidateDebitCardSuccessfull(false);
      setShowContinueBtn(false);

      setValidateDebitCardMessage(info.debitCardMessageWhenServiceError);
    } finally {
      setValidateLoading(false);
    }
  };

  const init = () => {
    if (variable.userInputOptions.defaultValue) {
      setHasValueLoaded(true);
      setShowContinueBtn(true);
      if (variable.userInputOptions.defaultValue === 'INVALID_CARD') {
        setShowMessageInvalidCardLoaded(true);
      } else {
        setShowMessageCardLoaded(true);
      }
    }

    if (dc.type) {
      setCardLogo(getCardLogoByType(dc.type));
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      <Grid container justifyContent="center" spacing={3}>

        {!hasValueLoaded && (
        <>
          <Grid item xs={12}>
            {isLoanRequestRevolvingLine(loanRequest) && (
              <>
                <Typography className="customer-data-title" style={{ marginBottom: '20px' }}>
                  {variable.userInputOptions.label}
                </Typography>
                <Typography align="left" style={{ marginBottom: '20px' }}>
                  {info.debitCardGroupDescription}
                </Typography>
              </>
            )}

            <Alert severity="info" icon={false} style={{ marginBottom: '20px' }}>
              <Typography style={{
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: 700,
                color: '#31708f',
                textAlign: 'left',
              }}
              >
                {variable.userInputOptions.hint}
              </Typography>
            </Alert>

            <Cards
              number={state.debitCardNumber}
              name={state.customerName}
              expiry={state.expirationDate}
              cvc={state.cvvCode}
              focused={state.focus}
              locale={{ valid: 'vence' }}
              placeholders={{ name: 'TU NOMBRE AQUI' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="debitCardNumber"
              label={info.debitCardNumberLabel}
              value={state.debitCardNumber}
              helperText={info.debitCardNumberHint}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              required={validateDebitCardSuccessfull !== false}
              disabled={validateDebitCardSuccessfull === true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="customerName"
              label={info.customerNameLabel}
              value={state.customerName}
              helperText={info.customerNameHint}
              placeholder={info.customerNamePlaceholder}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              required={validateDebitCardSuccessfull !== false}
              disabled={validateDebitCardSuccessfull === true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Alert
              severity="info"
              icon={false}
              style={{ backgroundColor: '#FFF9E5', color: 'grey' }}
            >
              <Typography style={{ fontFamily: 'Open Sans', fontSize: '10px' }}>
                {info.customerNameMessage}
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="expirationDate"
              label={info.expirationDateLabel}
              value={state.expirationDate}
              helperText={info.expirationDateHint}
              placeholder={info.expirationDatePlaceholder}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              inputProps={{
                style: {
                  color: state.expirationDate
                  && state.expirationDate.trim().length === 5
                  && isExpiryDateError(state.expirationDate) ? 'red' : 'black',
                },
              }}
              required={validateDebitCardSuccessfull !== false}
              disabled={validateDebitCardSuccessfull === true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="cvvCode"
              label={info.cvvLabel}
              value={state.cvvCode}
              helperText={info.cvvHint}
              placeholder={info.cvvPlaceholder}
              onChange={handleInputChange}
              required={validateDebitCardSuccessfull !== false}
              disabled={validateDebitCardSuccessfull === true}
              fullWidth
            />
          </Grid>
          {validateDebitCardSuccessfull === true && validateDebitCardMessage && (
          <>
            <Grid item xs={12}>
              <Alert severity="success" icon={false}>
                <Typography style={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '#3c763d',
                  padding: '5px',
                }}
                >
                  {validateDebitCardMessage}
                </Typography>
              </Alert>
            </Grid>
          </>
          )}
          {validateDebitCardSuccessfull === false && validateDebitCardMessage && (
          <>
            <Grid item xs={12}>
              <Alert severity="info" icon={false}>
                <Typography style={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 700,
                  whiteSpace: 'initial',
                  padding: '5px',
                }}
                >
                  {validateDebitCardMessage}
                </Typography>
              </Alert>
            </Grid>
          </>
          )}
          {!validateDebitCardSuccessfull && (
          <>
            <Grid item xs={12}>
              <Button
                variant="primary"
                className="btn-primary"
                onClick={sendValidation}
                loading={validateLoading}
                disabled={(state.debitCardNumber.trim().length < 12
                      || state.customerName.trim().length === 0
                      || isExpiryDateError(state.expirationDate)
                      || state.cvvCode.trim().length !== 3)}
                fullWidth
              >
                {info.sendValidationCTA}
              </Button>
            </Grid>
          </>
          )}

        </>
        )}

        {showMessageInvalidCardLoaded && (
        <>
          <Grid item xs={12}>
            <Alert
              severity="info"
              icon={false}
              style={{
                backgroundColor: '#d9edf7',
                color: '#31708f',
                border: '1px solid #bce8f1',
                borderRadius: '4px',
              }}
            >
              <Typography style={{
                fontFamily: 'Open Sans', fontSize: '14px', fontWeight: 400, whiteSpace: 'initial',
              }}
              >
                {info.invalidCardLoaded}
              </Typography>
            </Alert>
          </Grid>
        </>
        )}

        {showMessageCardLoaded && (
        <>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              style={{ display: 'Grid', marginTop: '24px' }}
            >
              <Grid item xs={12}>
                {isLoanRequestRevolvingLine(loanRequest) && (
                  <>
                    <Typography className="customer-data-title" style={{ marginBottom: '20px' }}>
                      {info.debitCardGroupLabel}
                    </Typography>
                    <Typography align="left" style={{ marginBottom: '20px' }}>
                      {info.debitCardGroupDescription}
                    </Typography>
                  </>
                )}
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    borderRadius: '15px',
                    backgroundColor: '#00B0D7',
                    textAlign: 'left',
                    width: '265px',
                  }}
                >
                  <Grid item xs={12} style={{ margin: '10px 0px 0px 10px' }}>
                    <img src={cardLogo} alt="card-logo" />
                  </Grid>
                  <Grid item xs={8} style={{ padding: '12px' }}>
                    <Typography style={{
                      color: 'white',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      textTransform: 'capitalize',
                    }}
                    >
                      {loanRequest.customerName.toLowerCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} textAlign="right" style={{ padding: '12px' }}>
                    <Typography style={{
                      color: 'white',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                    }}
                    >
                      {`**** ${variable.userInputOptions.defaultValue?.substring(12, variable.userInputOptions.defaultValue.length)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="tertiary"
              onClick={() => showCardToLoad(variable)}
              sx={{
                textTransform: 'none',
                fontSize: '13px',
                fontFamily: 'Open sans',
                padding: '0px',
                fontWeight: 100,
                display: 'contents',
              }}
            >
              {info.cardLoadadToChangeCTA}
            </Button>
          </Grid>
        </>
        )}

      </Grid>
    </>
  );
};

CustomerDataInputCard.propTypes = {
  variable: PropTypes.shape().isRequired,
  setShowContinueBtn: PropTypes.func.isRequired,
  resolveCardinalAssistance: PropTypes.func.isRequired,
  addPaymentModeVoluntary: PropTypes.func.isRequired,
  dc: PropTypes.shape().isRequired,
  setDC: PropTypes.func.isRequired,
  loanRequest: PropTypes.shape().isRequired,
};

export default CustomerDataInputCard;

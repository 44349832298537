import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Button, FileUpload } from '@doit/pcnt-react-ui-library';
import { Box, Grid, Typography } from '@mui/material';
import { getUploadDocumentImg, isMobileOperatingSystem, sendDataLayerEventObject } from '../utils/functionsUtil';
import '../assets/css/uploadDocument.css';
import i18n from '../common/i18n';
import { getBiometricOnboardingData, getUrlForUpload, logClientError } from '../api/onboardingService';
import LoadingOverlayCustom from './commons/LoadingOverlayCustom';
import DocumentLoadedList from './DocumentLoadedList';
import { useUserData } from '../contexts/userDataContext';
import { useGlobalLoading } from '../contexts/globalLoadingContext';
import { isLoanRequestRevolvingLine } from '../utils/loanRequestUtils';

const UploadDocument = ({
  document: documentToLoad, requiredDocumentList, onSuccess, onError, loanRequest,
}) => {
  const { hash } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [selectedImage, setSelectedImage] = useState();

  // Contexto para ver si debe ofrecer plan B o no
  const { userData } = useUserData();

  // Loading global, al setearse, otros componentes que esten "escuchando" este loading global tambien se
  // deshabilitaran o no en base a su valor.
  const { globalLoading, setGlobalLoading } = useGlobalLoading();

  const handleSelectImg = (image) => {
    try {
      if (image === undefined || image === null || image.length === 0) {
        const url = window.location.href;
        const message = `CLIENT_CONSOLE_LOAD_PHOTO - URL: ${url} - DOC: ${documentToLoad.name}. EMPTY IMAGE`;
        logClientError(message);
      }
    } catch (error) {
      console.log(error);
    }
    setSelectedImage(image[0]);
  };

  // CTA para subir foto carga normal
  const handleCTA = async () => {
    try {
      setGlobalLoading(true);
      sendDataLayerEventObject({ event: `cargar_foto_${documentToLoad.name}` });

      try {
        const url = window.location.href;
        const message = `CLIENT_CONSOLE_INIT_DOC_UPLOAD - URL: ${url} 
                        - DOC: ${documentToLoad.name} 
                        - DATA: ${selectedImage.size}Bytes - ${selectedImage.type} - ${selectedImage.name} `;
        logClientError(message);
      } catch (errorLogging) {
        console.log(errorLogging);
      }

      await getUrlForUpload(hash, documentToLoad.name, selectedImage);
      onSuccess();
    } catch (error) {
      console.log(error);
      onError(error);
    } finally {
      setGlobalLoading(false);
    }
  };

  // Crea un input hidden para el formulario de 4ID
  const createHiddenInput = (name, value) => {
    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = name;
    i.value = value;
    return i;
  };

  // Genera el formulario para redireccionar a 4ID
  const redirectBiometricOnboarding = (onboardingData) => {
    const f = document.createElement('form');
    const method = onboardingData.doGet ? 'get' : 'post';

    let { url } = onboardingData;
    if (queryParams.size > 0) {
      url += url.includes('?') ? '&' : '?';
      url += queryParams.toString();
    }

    f.setAttribute('method', method);
    f.setAttribute('action', url);
    f.appendChild(createHiddenInput('idapp', 'App Cliente'));
    f.appendChild(createHiddenInput('seckey', onboardingData.seckey));
    f.appendChild(createHiddenInput('country', 'ARG'));
    f.appendChild(createHiddenInput('idmaqc_service', onboardingData.idmaqcService));
    f.appendChild(createHiddenInput('profile_services', onboardingData.profileServices));
    f.appendChild(createHiddenInput('services', onboardingData.services));
    f.appendChild(createHiddenInput('externaltxid', onboardingData.externalId));
    f.appendChild(createHiddenInput('dni', onboardingData.customerId));
    f.appendChild(createHiddenInput('sexo', onboardingData.sex));

    // si esta emulado, coloco en el formulario los queryparams, para que viajen en el submit,
    // ya que se pierden solo si estan en el url action del form
    if (onboardingData.doGet) {
      const queryString = url.split('?')[1];
      const params = new URLSearchParams(queryString);
      params.forEach((value, key) => {
        f.appendChild(createHiddenInput(key, value));
      });
    }

    document.body.appendChild(f);
    f.submit();
  };

  // CTA para cargar selfie por biometria
  const handleBiometricCTA = async () => {
    try {
      setGlobalLoading(true);
      sendDataLayerEventObject({ event: 'validacion_biometria' });
      const res = await getBiometricOnboardingData(hash);
      redirectBiometricOnboarding(res.data);
    } catch (error) {
      onError(error);
    } finally {
      setGlobalLoading(false);
    }
  };

  const uploadByBiometric = () => documentToLoad?.userInputOptions?.inputMethod === '4ID' && !userData?.planB;

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        {!isLoanRequestRevolvingLine(loanRequest) && (
          <>
            <DocumentLoadedList requiredDocumentList={requiredDocumentList} />
            <Box mt={2} />
          </>
        )}
        {uploadByBiometric() ? (
          <>
            {/* CARGA DE DOCUMENTOS POR BIOMETRIA */}
            <Typography className="upload-document-title">{i18n.UploadDocument.biometricTitle}</Typography>
            <Typography className="upload-document-description" marginTop="16px">
              {parse(i18n.UploadDocument.biometricDescription)}
            </Typography>
            <img src="/assets/images/selfie.svg" alt="Selfie" width="150px" style={{ marginTop: '30px' }} />
            <Button
              variant="primary"
              className="btn-primary"
              fullWidth
              onClick={handleBiometricCTA}
              loading={globalLoading}
              sx={{ marginTop: '30px' }}
            >
              {i18n.UploadDocument.biometricCTALabel}
            </Button>
          </>
        ) : (
          <>
            {/* CARGA DE DOCUMENTOS SIN BIOMETRIA */}
            <Typography className="upload-document-title">{documentToLoad.userInputOptions.label}</Typography>
            <Typography className="upload-document-description" marginTop="16px" marginBottom="16px">
              {parse(documentToLoad.userInputOptions.hint.replace(/'\n'/g, '<br />'))}
            </Typography>
            <LoadingOverlayCustom loading={globalLoading}>
              <Box className="upload-document-fileupload">
                <FileUpload
                  buttonLabel={
                    isMobileOperatingSystem()
                      ? i18n.UploadDocument.fileUploadButtonLabelMobile
                      : i18n.UploadDocument.fileUploadButtonLabel
                  }
                  camera={isMobileOperatingSystem()}
                  icon={<img src={getUploadDocumentImg(documentToLoad.name)} alt="Upload" width="200" />}
                  onFileChange={handleSelectImg}
                  maxFileSize={1024 * 1024 * 15} // 15 MB
                />
              </Box>
            </LoadingOverlayCustom>
            {selectedImage && (
              <Box mt={2}>
                <Button
                  variant="primary"
                  className="btn-primary"
                  fullWidth
                  onClick={handleCTA}
                  loading={globalLoading}
                >
                  {i18n.UploadDocument.ctaLabel}
                </Button>
              </Box>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

UploadDocument.propTypes = {
  document: PropTypes.shape().isRequired,
  requiredDocumentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  loanRequest: PropTypes.shape().isRequired,
};

export default UploadDocument;
